<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
        >
            <template v-slot:filters>
                <AccountRoleFilter
                    ref="accountRoleFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCode="filterPayload.manCode"
                    v-model:role="filterPayload.role"
                    v-model:bgCode="filterPayload.bgCode"
                />
                <Dropdown
                    name="relationMode"
                    :disabled="true"
                    :title="filterTitles.relationMode"
                    :options="filterOptions.relationMode"
                    v-model="filterPayload.relationMode"
                />
                <Dropdown
                    name="dataType"
                    :required="true"
                    :title="filterTitles.dataType"
                    :options="filterOptions.dataType"
                    v-model="filterPayload.dataType"
                />
                <DateRangeTextbox
                    v-if="
                        filterShowDateColumns.showBothColumns ||
                        filterShowDateColumns.showReceivedColumns
                    "
                    startName="receiveDateStart"
                    endName="receiveDateEnd"
                    max="today"
                    min="default"
                    :required="true"
                    :tipObj="dateFilterTips.receive"
                    :hasLimit="true"
                    :limitDays="limitDays"
                    title="受理日"
                    v-model:startDate="filterPayload.receiveDateStart"
                    v-model:endDate="filterPayload.receiveDateEnd"
                />
                <DateRangeTextbox
                    v-if="filterShowDateColumns.showEffectColumns"
                    startName="effectDateStart"
                    endName="effectDateStart"
                    max="today"
                    min="default"
                    :hasLimit="true"
                    :tipObj="dateFilterTips.effect"
                    title="生效日"
                    v-model:startDate="filterPayload.effectDateStart"
                    v-model:endDate="filterPayload.effectDateEnd"
                />
                <DateRangeTextbox
                    v-if="
                        filterShowDateColumns.showBothColumns ||
                        filterShowDateColumns.showCommitColumns
                    "
                    startName="commitDateStart"
                    endName="commitDateEnd"
                    max="today"
                    min="default"
                    :required="true"
                    :tipObj="dateFilterTips.commit"
                    :isOnlyMonth="true"
                    :hasLimit="true"
                    :limitDays="limitDays"
                    title="核實月"
                    v-model:startDate="filterPayload.commitDateStart"
                    v-model:endDate="filterPayload.commitDateEnd"
                />

                <Dropdown
                    v-if="filterShowDateColumns.showReceivedColumns"
                    name="isAudit"
                    :required="true"
                    :title="filterTitles.isAudit"
                    :options="filterOptions.isAudit"
                    v-model="filterPayload.isAudit"
                />
            </template>
        </FilterModal>
    </div>
</template>

<script>
import DateRangeTextbox from '@/components/DateRangeTextbox.vue'
import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import { getReportIntervalCodesAPI } from '@/assets/javascripts/api'
import { intervalFilterTitles } from '@/assets/javascripts/report/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'IntervalFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        DateRangeTextbox,
        Dropdown,
        FilterModal,
        AccountRoleFilter
    },
    props: {
        mode: {
            type: String,
            default: '1'
        },
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    relationMode: '',
                    manCode: '',
                    endDate: '',
                    bgCode: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        stylerList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        resetFilter: function () {
            this.filterPayload = {
                receiveDateStart: this.$formatDate(
                    this.$getFirstDate(this.$getDate())
                ),
                receiveDateEnd: this.$formatDate(this.$getDate()),
                commitDateStart: this.$formatDate(
                    this.$getFirstDate(this.$getDate(0, -2))
                ),
                commitDateEnd: this.commitMaxDateDefault,
                ...filterDefault.interval
            }
            // includes mType, manCode(payload), role, bgCode(payload)
            this.$refs.accountRoleFilter.resetFilter()
        },
        getFilterData: function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
                this.filterLog = this.$getFilterLog(this.filterPayload)
                this.$store.commit('set', {
                    tableDeep: 0
                })
            }
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getReportIntervalCodesAPI()
                const { dataType, payWay, supplier } = res.data

                this.dropdownOptions = {
                    dataType,
                    payWay,
                    supplier
                }
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterOptions: function () {
            return {
                relationMode: [
                    {
                        label: '輔導線',
                        value: 'relation'
                    }
                ],
                isAudit: this.allOption.concat([
                    {
                        label: '已審',
                        value: true
                    },
                    {
                        label: '未審',
                        value: false
                    }
                ]),
                dataType: this.dropdownOptions.dataType.map((data) => {
                    return {
                        label: data.value,
                        value: data.key
                    }
                })
            }
        },
        commitMaxDateDefault: function () {
            const lastFirstDate = this.$getFirstDate(this.$getDate())
            return this.$formatDate(this.$getDate(-1, 0, 0, lastFirstDate))
        },
        filterShowDateColumns: function () {
            const isSelectedReceived = this.filterPayload.dataType === 1
            const isSelectedCommit = this.filterPayload.dataType === 2
            const isSelectedEffect = this.filterPayload.dataType === 3
            if (isSelectedEffect) {
                return {
                    showReceivedColumns: isSelectedReceived,
                    showEffectColumns: isSelectedEffect,
                    showCommitColumns: isSelectedCommit,
                    showBothColumns: true
                }
            }
            return {
                //只在受理階段顯示
                showReceivedColumns: isSelectedReceived,
                showEffectColumns: isSelectedEffect,
                showCommitColumns: isSelectedCommit,
                //只在受理且核實顯示
                showBothColumns: false
            }
        },
        dateFilterTips: function () {
            const tip1 = `為提供較佳的效能體驗，供查詢的資料區間僅限12個月`
            const effectTips = '生效日(起)、生效日(迄)須同時填寫，或同時清除'
            const Tips = {
                receive: { start: tip1, end: tip1 },
                effect: { start: effectTips, end: effectTips },
                commit: { start: tip1, end: tip1 }
            }
            if (this.filterPayload.dataType === 3)
                Tips.commit = { start: '', end: '' }

            return Tips
        },
        limitDays: function () {
            return { year: 1 }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        'filterPayload.dataType': {
            handler(val) {
                this.$reloadTips()
                if (val !== 1) {
                    this.filterPayload.isAudit = ''
                }
            }
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            filterTitles: {},
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            dropdownOptions: { dataType: [], payWay: [], supplier: [] }
        }
    },
    async mounted() {
        this.$nextTick(() => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...intervalFilterTitles,
                _seq: [...accountRoleTitles._seq, ...intervalFilterTitles._seq]
            }
        })
        await this.resetFilter()
        await this.getDropdownOptions()
        // await this.getFilterData()
    }
}
</script>

<style lang="scss" scoped>
.effect-date {
    :deep(.required) {
        display: none;
    }
}
</style>
